<template>
  <v-card>
    <!-- start of toolbar 2 -->
    <v-toolbar color="secondary" flat dense>
      <v-btn
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="loader"
      >
        <v-icon left dark>mdi-content-save</v-icon>Save
      </v-btn>
    </v-toolbar>
    <!-- end of toolbar 2 -->
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-text-field
            outlined
            dense
            v-model="record.Name"
            label="Route Name"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            dense
            type="number"
            v-model="record.Code"
            label="Route Code"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            label="Region"
            v-model="region.descript"
            outlined
            dense
            @click="openRegionModal"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            dense
            v-model="record.Origin"
            label="Origin"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            dense
            v-model="record.Destination"
            label="Destination"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-checkbox label="Active" v-model="record.Active"></v-checkbox>
        </v-col>

        <v-col cols="4">
          <v-textarea
            v-model="record.Dscription"
            label="Description"
            auto-grow
            outlined
            document_lines="1"
            row-height="15"
          ></v-textarea>
        </v-col>

        <!-- <v-col cols="8">
          <div class="d-flex align-end">
            <v-btn color="accent" @click="sendData">
              <v-icon left>mdi-content-save</v-icon>Save
            </v-btn>
          </div>
        </v-col> -->
      </v-row>

      <!-- start of territories dialog -->
      <v-dialog v-model="regionDialog" width="650px">
        <v-card>
          <v-toolbar color="accent" dark dense>
            <v-toolbar-title>Select Territory</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="regionDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-treeview
                  color="accent"
                  :activatable="trueValue"
                  :hoverable="trueValue"
                  :selection-type="selectionType"
                  open-all
                  return-object
                  item-text="descript"
                  item-key="id"
                  :items="territories"
                  item-children="children_recursive"
                  :search="search"
                >
                  <template v-slot:label="{ item }">
                    <div @click="selectRegion(item)">
                      <span style="font-weight: bold">{{ item.descript }}</span>
                    </div>
                  </template>
                </v-treeview>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end of territories dialog -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
    },
  },
  data() {
    return {
      record: {},
      region: {},
      loader: null,
      regionDialog: false,
      territories: [],
      search: null,
      selectionType: "leaf",
      trueValue: true,
    };
  },
  watch: {
    initial: {
      handler: "setRoute",
      immediate: true,
    },
  },
  methods: {
    setRoute(val) {
      this.record = { ...val };
    },
    openRegionModal() {
      this.regionDialog = true;
    },
    selectRegion(data) {
      this.territory = data;
      const id = data.id;
      const self = this;
      self.loading = true;
      // api call
      this.$store
        .dispatch("get", `/territories/${id}`)
        .then((res) => {
          console.log(res);
          self.region = res;
          //  const recordData = self.record;
          self.record.region = self.region;
          self.record.TerritoryID = self.region.id;
          self.loading = false;
          self.regionDialog = false;
        })
        .catch((err) => {
          console.log(err, "error");
          this.$refs.snackbar.show(err, "red");
        });
      // end
    },
    getTerritories() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/territories`)
        .then((res) => {
          self.territories = res.ResponseData.territories;
          self.loading = false;
          console.log(self.territories);
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    sendData() {
      this.$store.commit("loader", true);
      this.$emit("data", this.record);
    },
  },
  created() {
    this.getTerritories();
  },
};
</script>
